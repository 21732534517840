<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">Anti-Money Laundering Policy</h1>
      </div>
      <div class="box b-shdw-3 content">
        <h2>1. Background</h2>
        <p>Money laundering is a term used to describe the techniques, procedures or processes used to convert funds
          obtained from criminal activities into other assets in such a way as to conceal their true origin, and to give
          the appearance that the money has come from a legitimate or lawful source.</p>
        <p>UK criminal law, including the Proceeds of Crime Act 2002, imposes a mandatory obligation on all persons
          within the regulated financial sector to report, as soon as is practicable, any knowledge or suspicion of
          money laundering. Furthermore, it is an offence for any person:</p>
        <ul>
          <li>To provide assistance to a money launderer to obtain, conceal, retain, or invest funds;</li>
          <li>To aid a money launderer to acquire property or products using criminal funds;</li>
          <li>To prejudice an investigation by informing the subject of a suspicion, or any third party, that a
            disclosure has been made, or that the authorities are acting or proposing to act or investigate; and
          </li>
          <li><p>To fail to make a disclosure of money laundering where there are reasonable grounds of knowledge or
            suspicion.</p>
          </li>
          <li><p><strong>Policy Statement</strong></p>
          </li>
        </ul>
        <p>Rush Insurance Services is committed to complying with all anti-money laundering laws and regulations for the
          purposes of combatting money laundering and terrorist financing.</p>
        <h2>2. Scope</h2>
        <p>This Policy applies to all employees, contractors, subcontractors, consultants, appointed representatives and
          outsourcers (collectively referred to as &#39;staff&#39;) which transact business on the Rush Insurance
          Services’ behalf.</p>
        <h2>3. Compliance</h2>
        <p>Rush Insurance Services expects its staff to be aware of the main areas of vulnerability and factors which
          may give rise to suspicion. These include but are not limited to:</p>
        <ul>
          <li>Persons whose identity cannot easily be verified;</li>
          <li>Persons who refuse to provide the information or documentation required;</li>
          <li>The source of a premium payment which is difficult to identify, or a premium which is received from a
            third party with no obvious connection to the customer;
          </li>
          <li>A customer wanting to pay a large premium in cash;</li>
          <li>Payments made from (or a request for a refund to) a foreign bank account or branch (particularly if the
            customer does not have an obvious reason for conducting business within that country);
          </li>
          <li>An overpayment of the premium with a request to make the excess payable to a third party;</li>
          <li>Early cancellation of a policy in circumstances which seem unusual or for no apparent reason;</li>
          <li>Cancellation of a policy and a request that the refund be paid to a third party with no obvious connection
            with the customer;
          </li>
          <li>A request to pay a claim to a third party which has no obvious connection with the customer;</li>
          <li>Extensive use of corporate structures and trusts in circumstances where the customer’s needs are
            inconsistent with the use of these structures and trusts;
          </li>
          <li>Customers wishing to insure assets well in excess of their lifestyle or occupation;</li>
          <li>Persons where information is available to suggest that they may be involved in criminal activities; and
          </li>
          <li>Persons with businesses which makes it impossible to verify the legitimacy of the activities or the origin
            of the funds.
          </li>
        </ul>
        <p>Penalties for failing to observe the law can include imprisonment of up to 14 years.</p>
        <h2>4. Reporting</h2>
        <p>Any employee who suspects a money laundering offence may be, or may have been, committed in the course of the
          Rush Insurance Services&#39; business should refer the suspicion to <a
              href="mailto:compliance@rushinsurance.co.uk">compliance@rushinsurance.co.uk</a> or the Director of Finance.
          This should be immediately upon discovery or suspicion, not weeks or months later. Under no circumstances
          should any suspicions be shared with the person(s) concerned.</p>
        <p>Where the suspected money laundering offence relates to a customer or potential customer in connection with
          payments into or from a bank account where funds are held as agent of the insurer, the Compliance Officer
          should immediately notify the insurer/s or managing agent concerned.</p>
        <p>This will be referred to the Money Laundering Officer who will consider whether a Suspicious Activity Report
          (SAR) should be submitted to the National Crime Agency (NCA) and document the rationale behind their reason
          for making an SAR or otherwise.</p>
        <h2>5. Systems and Controls</h2>
        <p>Rush Insurance Services will establish and maintain appropriate, risk-based procedures, systems and controls
          and monitoring arrangements to support the implementation of this Policy.</p>
        <h2>6. Breach Reporting</h2>
        <p>Any breaches of this Policy, or practices which are considered not to be compatible with it, should be
          brought to the attention of Compliance Officer at <a href="mailto:compliance@rushinsurance.co.uk">compliance@rushinsurance.co.uk</a>.
        </p>
        <h2>7. Review of this Policy</h2>
        <p>This Policy will be reviewed annually or as otherwise stated in the Rush Insurance Services Compliance
          Monitoring Plan.</p>
        <p>(Last updated September 2024)</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AntiMoneyLaunderingPolicy",
}
</script>

<style lang="scss" scoped>
.level-item {
  padding: 40px 0 40px 0;
}
h2 {
  color: $c2g_blue;
  font-weight: bold;
  font-size: 20px;
  margin: 15px 0 5px 0;
}
</style>